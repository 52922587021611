import FinalReport from '../../../assets/FinalReport.png';
import ContentCreation from '../../../assets/ae_content.png';
import ExamIcon from '../../../assets/ae_exam.png';
import ManageExams from '../../../assets/ae_manage_exams.png';
import ManageUsers from '../../../assets/ae_manage_users.png';
import Analytics from '../../../assets/ae_overallanalytics.png';
import PaidExamIcon from '../../../assets/ae_paid_exam.png';
import ReportIcon from '../../../assets/ae_report.png';
import ManageTenants from '../../../assets/ae_tenant.png';
import OfferLetterIcon from '../../../assets/Final_Selected_Students.png'
import GD from '../../../assets/gd.png';
import { ALL_REPORTS, ASSIGNED_EXAMS, CONTENT_CREATION, EXAM_REPORTS, EXAM_TYPE_ALL, EXAM_TYPE_ASSIGNED, EXAM_TYPE_PRACTICE, FINAL_SELECTED_LIST, GD_REPORTS, INTERVIEW_REPORTS, MANAGE_EXAMS, MANAGE_GD, MANAGE_TENANTS, MANAGE_USERS, OVERALL_ANALYTICS, OVERALL_REPORTS, PRACTICE_EXAMS, REPORTS } from '../constants/constants';

export const FACADES = [
    {
        id: PRACTICE_EXAMS,
        title: "Practice Exams",
        imageUrl: ExamIcon,
        imageAlt: "Practice Exams",
        navigateTo: "/user/exams",
        navigateState: { examType: EXAM_TYPE_PRACTICE },
        description: "AE practice exams improves your skills, makes you comfortable with the platform and allows you to better manage the time and duration."
    },
    {
        id: ASSIGNED_EXAMS,
        title: "Assigned Exams",
        imageUrl: PaidExamIcon,
        imageAlt: "Assigned Exams",
        navigateTo: "/user/exams",
        navigateState: { examType: EXAM_TYPE_ASSIGNED },
        description: "AE assigned exams created by either admin, your current or future organization to assess you for an employment or a training."
    },
    {
        id: REPORTS,
        title: "My Reports",
        imageUrl: ReportIcon,
        imageAlt: "My Reports",
        navigateTo: "/user/reports",
        navigateState: { examType: EXAM_REPORTS },
        description: "Collective information of the past assinged or practice exams that are with finished status. You can view/download the merit certificate from each exam."
    },
    {
        id: MANAGE_TENANTS,
        title: "Manage Tenants",
        imageUrl: ManageTenants,
        imageAlt: "Manage Tenants",
        navigateTo: "/console/tenant",
        description: "Manage the tenants, groups and users into the single unit.",
    },
    {
        id: MANAGE_USERS,
        title: "Manage Users",
        imageUrl: ManageUsers,
        imageAlt: "Manage Users",
        navigateTo: "/console/users",
        description: "Search and manage the user state, modify the group and update the authority.",
    },
    {
        id: MANAGE_EXAMS,
        title: "Manage Exams",
        imageUrl: ManageExams,
        imageAlt: "Paid Exams",
        navigateTo: "/console/examtypes",
        navigateState: { examType: EXAM_TYPE_ALL },
        description: "Manage exam creation, modification, deletion and assignment to either user, group or tenant."
    },
    ,
    {
        id: MANAGE_GD,
        title: "Group Discussion",
        imageUrl: GD,
        imageAlt: "Manage Group Discussion",
        navigateTo: "/gd/rooms",
        description: "Manage group discussion room allocation, creation, modification, and report generation."
    },
    {
        id: CONTENT_CREATION,
        title: "Manage Content",
        imageUrl: ContentCreation,
        imageAlt: "Content Creation",
        navigateTo: "/content/dashboard",
        description: "Manage content creation. Create content either manual or using the file upload. Sample upload schema is available on each content type."
    },
    {
        id: OVERALL_ANALYTICS,
        title: "View Analytics",
        imageUrl: Analytics,
        imageAlt: "OVERALL ANALYTICS",
        navigateTo: "/console/dashboard",
        description: "Displays overall user Analytics. You can also see which user attempted how many exams."
    },
    {
        id: ALL_REPORTS,
        title: "Reports",
        imageUrl: ReportIcon,
        imageAlt: "Reports",
        navigateTo: "/console/tenants",
        description: "Collective information of the past assinged or practice exams that are with finished status."
    },

    {
        id: FINAL_SELECTED_LIST,
        title: "Selected Students",
        imageUrl: OfferLetterIcon,
        imageAlt: "OfferLetter",
        navigateTo: "/selected/students",
        description: "This Contains students data who got their offerletters by clearing all the rounds"
    }]

export const REPORT_TILES = [
    {
        id: EXAM_REPORTS,
        title: "Exam Reports",
        imageUrl: ReportIcon,
        imageAlt: "Reports",
        navigateTo: "/reports/tenants/dashboard/exam",
        description: "Collective information of the past assinged or practice exams that are with finished status."
    },
    // {
    //     id: GD_REPORTS,
    //     title: "Group Discussion Reports",
    //     imageUrl: FinalReport,
    //     imageAlt: "Group Discussion Reports",
    //     navigateTo: "/reports/tenants/dashboard/gd",
    //     description: "Overall Report of the Students that cleared all the inteview rounds"
    // }, 
    {
        id: OVERALL_REPORTS,
        title: "Final Reports",
        imageUrl: FinalReport,
        imageAlt: "Overall Report",
        navigateTo: "/reports/tenants/dashboard/overall",
        description: "Final Report of the Students that cleared Exam and Group Discussion rounds"
    }, {
        id: INTERVIEW_REPORTS,
        title: "Interview Reports",
        imageUrl: FinalReport,
        imageAlt: "Interview Reports",
        navigateTo: "/reports/tenants/dashboard/interview",
        description: "Overall Report of the Students that cleared all the inteview rounds"
    },

]