export const ROLE_USER = "ROLE_USER";
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_EXAM_MAKER = "ROLE_EXAM_MAKER";
export const ROLE_ORG_ADMIN = "ROLE_ORG_ADMIN";
export const ROLE_CAMPUS_USER = "ROLE_CAMPUS_USER";
export const ROLE_OBSERVER = "ROLE_OBSERVER";

export const PRACTICE_EXAMS = "PRACTICE_EXAMS";
export const ASSIGNED_EXAMS = "ASSIGNED_EXAMS";

export const MANAGE_EXAMS = "MANAGE_EXAMS";
export const MANAGE_GD = "MANAGE_GD";
export const MANAGE_USERS = "MANAGE_USERS";
export const MANAGE_TENANTS = "MANAGE_TENANTS";

export const EXAM_TYPE_PRACTICE = "PRACTICE";
export const EXAM_TYPE_ASSIGNED = "ASSIGNED";

export const REPORTS = "REPORTS";
export const ALL_REPORTS = "ALL_REPORTS";
export const EXAM_REPORTS = "EXAM REPORTS";
export const GD_REPORTS = "GROUP DISCUSSION REPORTS";
export const INTERVIEW_REPORTS = "INTERVIEW REPORT";
export const OVERALL_REPORTS = "FINAL_REPORTS";
export const FINAL_SELECTED_LIST = "FINAL REPORT";


export const EXAM_TYPE_ALL = "ALL";

export const CONTENT_CREATION = "CONTENT CREATION";
export const QUESTIONS_CREATION = "CREATE QUESTIONS FROM FILE";
export const QUESTION_CREATION = "CREATE QUESTION";
export const ANALYTICS = "ANALYTICS";
export const EXAM_ANALYSIS = "EXAM ANALYSIS";
export const USER_ANALYSIS = "USER ANALYSIS";
export const OVERALL_ANALYTICS = "OVRALL ANALYTICS";
export const EXAM_ISFINISHED = "FINISHED";


export const ROLES = [
    ROLE_USER,
    ROLE_SUPER_ADMIN,
    ROLE_EXAM_MAKER,
    ROLE_ORG_ADMIN,
    ROLE_CAMPUS_USER,
    ROLE_CAMPUS_USER,
    ROLE_OBSERVER
];


export const ROLE_BASED_RESOURCES = {
    [ROLE_USER]: [PRACTICE_EXAMS, ASSIGNED_EXAMS, REPORTS],
    [ROLE_OBSERVER]: [MANAGE_GD],
    [ROLE_SUPER_ADMIN]: [MANAGE_EXAMS, MANAGE_GD, MANAGE_USERS, MANAGE_TENANTS, CONTENT_CREATION, ANALYTICS, OVERALL_ANALYTICS, ALL_REPORTS, OVERALL_REPORTS, FINAL_SELECTED_LIST],
    [ROLE_EXAM_MAKER]: [MANAGE_EXAMS, CONTENT_CREATION],
    [ROLE_CAMPUS_USER]: [ASSIGNED_EXAMS],
    [ROLE_OBSERVER]:[MANAGE_GD],
    [ROLE_ORG_ADMIN]: [MANAGE_USERS, MANAGE_TENANTS, ANALYTICS, OVERALL_ANALYTICS]
};


export const HOME_ROUTE_PATH = "/home";

export const routes = [
    { id: "Exams", path: "/console/examtypes", state: { examType: EXAM_TYPE_ALL } },
    { id: "Practice Exams", path: "/console/examtypes/exams", state: { examType: EXAM_TYPE_PRACTICE } },
    { id: "Assigned Exams", path: "/console/examtypes/exams", state: { examType: EXAM_TYPE_ASSIGNED } },
    { id: "Create Exam", path: '/console/exams/create' },
    { id: "Reports", path: "/console/tenants" },
    { id: "Analytics", path: "/console/dashboard/analytics" },
    { id: "Exam Analytics", path: "/console/dashboard/examanalysis" },
    { id: "User Analytics", path: "/console/dashboard/user" },
    { id: "Create Questions", path: "/content/dashboard/managequestion/question" },
    { id: "Create Questions(File Upload)", path: "/content/dashboard/managequestion/fileupload" },
    { id: "Manage Course", path: "/content/dashboard/setups" },
    { id: 'Create User', path: "/console/tenant/group/user/create" },
    { id: "Create Group Discussion", path: "/gd/rooms/create" },
    { id: "Group Discusssion", path: "/gd/rooms" },
    { id: "Final Report ", path: "/final/tenant" }

]