import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.ae.layout/Layout";
import { EXAM_INSTRUCTIONS_PATH, EXAM_PATH, EXAM_REPORT_PATH, EXAM_SESSION_ID_PATH } from "../constants/constants";

const InstructionsComponent = React.lazy(() => import("../instructions/Instructions"));
const ExamsListComponent = React.lazy(() => import("../Exams"));
const ExamSessionComponent = React.lazy(() => import("../session/ExamSessionContainer"));
const ExamReportComponent = React.lazy(() => import("../session/ExamReport"));
const GdCreateComponent = React.lazy(() => import("../gd/CreateGd"));
const GdRoomComponent = React.lazy(() => import("../gd/GdRoom"));
const GdRooms = React.lazy(() => import("../gd/Rooms"));
const FeedbackComponent = React.lazy(() => import("../Feedback"));

export const EXAM_ROUTES = [
    <Route path={EXAM_INSTRUCTIONS_PATH}
        element={
            <PrivateRoute>
                <Layout component={InstructionsComponent} path={EXAM_INSTRUCTIONS_PATH} title="Instructions"></Layout>
            </PrivateRoute>
        }
    />,
    <Route
        path={EXAM_PATH}
        element={
            <PrivateRoute>
                <Layout component={ExamsListComponent} path={EXAM_PATH} title="Exams"></Layout>
            </PrivateRoute>
        }
    />,
    <Route
        path={EXAM_SESSION_ID_PATH}
        element={
            <PrivateRoute>
                <Layout component={ExamSessionComponent} path={EXAM_SESSION_ID_PATH} Title="Exam Session"></Layout>
            </PrivateRoute>
        }
    />,
    <Route
        path={EXAM_REPORT_PATH}
        element={
            <PrivateRoute>
                <Layout component={ExamReportComponent} path={EXAM_REPORT_PATH} Title="Exam Report"></Layout>
            </PrivateRoute>
        }
    />
]

export const GD_ROUTES = [
    <Route
        path="/gd/rooms"
        element={
            <PrivateRoute>
                <Layout component={GdRooms} path={"/gd/rooms"} title="GD Rooms"></Layout>
            </PrivateRoute>
        }
    />,
    <Route
        path="/gd/rooms/create"
        element={
            <PrivateRoute>
                <Layout component={GdCreateComponent} path={"/gd/rooms/create"} title="Create GD"></Layout>
            </PrivateRoute>
        }
    />,
    <Route
        path="/gd/rooms/view"
        element={
            <PrivateRoute>
                <Layout component={GdRoomComponent} path={"/gd/rooms/view"} title="GD Room"></Layout>
            </PrivateRoute>
        }
    />,
    <Route
        path="/exam/feedback"
        element={
            <PrivateRoute>
                <Layout component={FeedbackComponent} path={"/exam/feedback"} title="Feedback"></Layout>
            </PrivateRoute>
        }
    />,
];