import { Avatar, Grid, Stack, } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { useNavigate } from "react-router-dom"
import EdvenswaLogo from "../../../assets/Edvenswa-logo-ACL-12x12 2.png"
import AELogo from "../../../assets/ae_logo.png"

Logo.propTypes = {
    xs: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired
}

const styles = {
    ae_logo_typography: {
        marginRight: 2,
        fontFamily: 'monospace',
        fontWeight: 600,
        letterSpacing: '.3rem',
        color: '#000000',
        textDecoration: 'none'
    }
}

export default function Logo(props) {

    const navigate = useNavigate();

    const { xs, md } = props;
    const flexGrow = (xs === 'flex' ? 1 : 0);

    const handleLogoClick = (event) => {
        navigate("/home");
    }

    return (
        <React.Fragment>
            <Grid sx={{ display: "flex" }}>
                <Stack direction={'row'} spacing={2}>
                    <Avatar
                        alt={EdvenswaLogo}
                        color="primary"
                        src={EdvenswaLogo}
                        sx={{ objectFit: "fill", width: 57, height: 57, overflow: "visible" }}
                        onClick={handleLogoClick} />
                    <Grid sx={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                    <Avatar
                        alt={AELogo}
                        color="primary"
                        src={AELogo}
                        sx={{ objectFit: "fill", width: 37, height: 37, overflow: "visible" }}
                        onClick={handleLogoClick} />
                        
                    </Grid>
                </Stack>
            </Grid>
        </React.Fragment>
    )
};