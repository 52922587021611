import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../components/edvenswa.ae.auth/constants/constants";
import { axiosInstance } from "../interceptors/AxiosInterceptor";
import React, { useState } from "react";

PrivateRoute.propTypes = {
    children: PropTypes.element.isRequired
}

export default function PrivateRoute({ children }) {
    const [username, setUsername] = useState(null);
    const user = JSON.parse(sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY));
    if (user && user.token) {
        if (username === null)
            setUsername(user.username)
        return children;
    }
    else {
        axiosInstance.delete(`/auth/remove/session?username=${username}`)
            .then((res) => {
                console.log("session deleted")
            }).catch((err) =>{
              console.log(err);
            })
    }
    return <Navigate to="/" replace></Navigate>
}