import { ArrowForwardOutlined, Close, SearchOutlined } from "@mui/icons-material";
import { AppBar, Avatar, Box, Container, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, Stack, TextField, Toolbar, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import Logo from "./logo/Logo";
import SettingsMenu from "./settings/SettingsMenu";
import { useEffect, useState, useRef } from "react";
import { axiosInstance } from "../../interceptors/AxiosInterceptor";
import { useNavigate } from "react-router";
import { routes } from "../edvenswa.ae.home/constants/constants";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../edvenswa.ae.auth/constants/constants";

Header.propTypes = {
    user: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired
};

export default function Header(props) {
    const user = JSON.parse(sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY));
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [localRoutes, setLocalRoutes] = useState([]);
    const navigate = useNavigate();
    const searchRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSearchTerm("");
                setUsers([]);
                setLocalRoutes([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchRef]);

    useEffect(() => {
        const fetchData = async () => {
            if (searchTerm.length === 0) {
                setUsers([]);
                setLocalRoutes([]);
                return;
            }

            try {
                const { data } = await axiosInstance.post('/tenant/search', { searchTerm, searchType: 'USER' });
                if (data && data.length > 0) {
                    setUsers(data);
                    setLocalRoutes([]);
                } else {
                    const matchingRoutes = routes.filter(route => route.id.toLowerCase().includes(searchTerm.toLowerCase()));
                    setLocalRoutes(matchingRoutes);
                    setUsers([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, [searchTerm]);

    const handleListItemClick = (path, state) => {
        navigate(path, { state });
        setSearchTerm("");
        setLocalRoutes([]);
    };

    return (
        <AppBar  position="fixed" sx={{ backgroundColor: "#ffffff" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Logo xs={'flex'} md={'flex'} />
                    {user?.roles[0] === "ROLE_SUPER_ADMIN" && (
                        <Box sx={{ position: 'relative' }} ref={searchRef}>
                            <TextField
                                style={{ width: '400px', borderRadius: '5px' }}
                                size="small"
                                placeholder="Search students or actions (Ex: Exams, Reports)"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlined />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Close
                                                style={{ cursor: 'pointer' }}
                                                fontSize='small'
                                                sx={{ color: "#1C243C" }}
                                                onClick={() => {
                                                    setSearchTerm("");
                                                    setUsers([]);
                                                    setLocalRoutes([]);
                                                }}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                value={searchTerm}
                                onChange={(event) => setSearchTerm(event.target.value)}
                            />
                            {(users.length > 0 || localRoutes.length > 0) && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '100%',
                                        zIndex: 1,
                                        width: '100%',
                                        maxWidth: '350px',
                                        bgcolor: '#f5f5f5',
                                        marginTop: 1,
                                    }}
                                >
                                    <List>
                                        {users.map((element, key) => (
                                            <ListItemButton key={key} onClick={() => handleListItemClick("/console/dashboard/user", { username: element.username })}>
                                                <ListItemIcon>
                                                    <Avatar alt={element?.username} color="primary" src={element?.profileImage} />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={<Typography style={{ color: 'black' }}>{`${element.firstName} ${element.lastName}`}</Typography>}
                                                    secondary={element?.tenant?.tenantName}
                                                />
                                            </ListItemButton>
                                        ))}
                                        {localRoutes.map((element, key) => (
                                            <ListItemButton key={key} onClick={() => handleListItemClick(element.path, element.state)}>
                                                <ListItemText
                                                    primary={
                                                        <Stack direction={'row'} spacing={2}>
                                                            <ArrowForwardOutlined style={{ color: 'black' }} />
                                                            <Typography style={{ color: 'black' }}>{element.id}</Typography>
                                                        </Stack>
                                                    }
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Box>
                            )}
                        </Box>
                    )}
                    <SettingsMenu {...props} />
                </Toolbar>
            </Container>
        </AppBar>
    );
}
