import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.ae.layout/Layout";
import { ANALYTICS_PATH } from "../constants/constants";
const AnalyticsComponent = React.lazy(() => import("../../edvenswa.ae.analytics/analytics/Analytics"));
const AnalyticsDashboardComponent = React.lazy(() => import("../../edvenswa.ae.analytics/dashboard/analyticsdashboard"));
const ExamAnalysisDashboardComponent = React.lazy(() => import("../../edvenswa.ae.analytics/analytics/ExamAnalysis"));
const UserAnalysisDashboardComponent = React.lazy(() => import("../../edvenswa.ae.analytics/analytics/UserAnalysisCard"));
const UserExamAnalyticsComponent = React.lazy(() => import("../../edvenswa.ae.analytics/analytics/UserExamAnalytics"));
const ExamPreviewComponent = React.lazy(() => import("../../edvenswa.ae.analytics/analytics/ExamPreview"))
const RankingComponent = React.lazy(() => import("../analytics/Rankings"));
const ExamAnalyticsByGradeComponent = React.lazy(() => import("../analytics/ExamAnalyticsByGrade"));
const NewAnalytics = React.lazy(() => import("../analytics/NewAnalytcis"));


export const ANALYTICS_ROUTES = [
    <Route path={ANALYTICS_PATH}
        element={
            <PrivateRoute>
                <Layout component={AnalyticsComponent}
                    path={ANALYTICS_PATH}
                    title="Analytics"></Layout>
            </PrivateRoute>
        }
    />,
    ,
    <Route path="/console/dashboard/analytics"
        element={
            <PrivateRoute>
                <Layout component={NewAnalytics}
                    path="/console/dashboard/analytics"
                    title="Analytics"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/dashboard"
        element={
            <PrivateRoute>
                <Layout component={AnalyticsDashboardComponent}
                    path="/console/dashboard"
                    title="Dashboard"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/dashboard/examanalysis"
        element={
            <PrivateRoute>
                <Layout component={ExamAnalysisDashboardComponent}
                    path="/console/dashboard/examanalysis"
                    title="Exam Analytics"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/dashboard/user"
        element={
            <PrivateRoute>
                <Layout component={UserAnalysisDashboardComponent}
                    path="/console/dashboard/user"
                    title="User Reports"></Layout>
            </PrivateRoute>
        }

    />,
    <Route path="/console/dashboard/user/analytics"
        element={
            <PrivateRoute>
                <Layout component={UserExamAnalyticsComponent}
                    path="/console/dashboard/user/analytics"
                    title="Detailed Analysis"></Layout>
            </PrivateRoute>
        }

    />,
    <Route path="/console/dashboard/examanalysis/preview"
        element={
            <PrivateRoute>
                <Layout component={ExamPreviewComponent}
                    path="/console/dashboard/examanalysis/preview"
                    title="Exam Preview"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/dashboard/examanalysis/rankings"
        element={
            <PrivateRoute>
                <Layout component={RankingComponent}
                    path="/console/dashboard/examanalysis/rankings"
                    title="Rankings"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/dashboard/examanalysis/charts"
        element={
            <PrivateRoute>
                <Layout component={ExamAnalyticsByGradeComponent}
                    path="/console/dashboard/examanalysis/charts"
                    title="Rankings"></Layout>
            </PrivateRoute>
        }
    />,

];