export const AUTH_USERNAME_FIELD_ID = 'username';
export const AUTH_PASSWORD_FIELD_ID = 'password';
export const AUTH_FIRSTNAME_FIELD_ID = 'firstName';
export const AUTH_LASTNAME_FIELD_ID = 'lastName';
export const AUTH_PROFILEIMAGE_FIELD_ID = 'profileImage';


export const AUTH_CONFRIM_PASSWORD_FIELD_ID = 'confirmPassword';
export const AUTH_GENDER_FIELD_ID = 'gender';
export const AUTH_TENANT_FIELD_ID = 'tenant';
export const AUTH_ROLE_FIELD_ID = 'role';
export const AUTH_MOBILE_FIELD_ID = 'mobile';
export const AUTH_GROUP_FIELD_ID = 'group';
export const AUTH_OTP_FIELD_ID = 'otp';
export const AUTH_ERANK_FIELD_ID = 'eamcetRank';
export const AUTH_CGPA_FIELD_ID = 'cgpa';
export const AUTH_BACKLOGS_FIELD_ID = 'backlogs';
export const AUTH_SEND_OTP = "GET_OTP";
export const AUTH_PROCEED_SIGNUP = "PROCEED_SIGNUP";

export const AUTH_SECURE_USER_DETAILS_LS_LEY = "_secure_user_details";
export const AUTH_EMAIL_REGUALR_EXPRESSION = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
export const AUTH_PASSWORD_REGULAR_EXPRESSION = "^(?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[0-9])(?=.*[@$!%*?&#])[A-Za-z0-9@$#!%*?&]{8,}$";
