import React from 'react'
import { REPORT_TILES } from '../edvenswa.ae.home/tiles/Facades'
import Dashboard from '../edvenswa.ae.home/Dashboard'
import { Container } from '@mui/material';
import { useLocation } from 'react-router';

const ReportDashboard = (props) => {
    const location = useLocation();
    const tenantName = localStorage.getItem("tenantName");
    const titles = REPORT_TILES;
    return (
        <Container component={"main"} maxWidth="lg">
            <Dashboard tenantName={tenantName} contentTiles={titles} />
        </Container>
    )
}

export default ReportDashboard
