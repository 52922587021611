import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.ae.layout/Layout";
import ReportDashboard from "../ReportDashboard";

const ReportComponent = React.lazy(() => import("../report/Reports"));
const ReportTableComponent = React.lazy(() => import("../ReportsTable"));
const TeanantReportComponent = React.lazy(() => import("../TenantReports"));
const FinalReportComponent = React.lazy(() => import("../FinalReport"));
const UserExamAnalyticsComponent = React.lazy(() => import("../../edvenswa.ae.analytics/analytics/UserExamAnalytics"));
const InterviewFormComponent = React.lazy(() => import("../InterviewForm"));
const ReportDashboardComponent = React.lazy(() => import("../ReportDashboard"));
const SelectedStudentsComponent = React.lazy(() => import("../SelectedStudents"))
const InterviewReportComponent = React.lazy(() => import("../InterviewReport"));

export const REPORT_ROUTES = [
    <Route path="/user/reports"
        element={
            <PrivateRoute>
                <Layout
                    component={ReportComponent}
                    path="/user/reports"
                    title="Reports">
                </Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/tenants/reports"
        element={
            <PrivateRoute>
                <Layout
                    component={ReportTableComponent}
                    path="/console/tenants/reports"
                    title="Reports">
                </Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/reports/tenants/dashboard/interview"
        element={
            <PrivateRoute>
                <Layout
                    component={InterviewReportComponent}
                    path="/reports/tenants/dashboard/interview"
                    title="Interview Reports">
                </Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/reports/tenants/dashboard"
        element={
            <PrivateRoute>
                <Layout
                    component={ReportDashboardComponent}
                    path="/reports/tenants/dashboard"
                    title="Reports Dashboard">
                </Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/tenants/reports/userAnalytics"
        element={
            <PrivateRoute>
                <Layout component={UserExamAnalyticsComponent}
                    path="/console/tenants/reports/userAnalytics"
                    title="User Analysis"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/console/tenants"
        element={
            <PrivateRoute>
                <Layout component={TeanantReportComponent}
                    path="/console/tenants"
                    title="Tenants"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/reports/tenants/dashboard/overall"
        element={
            <PrivateRoute>
                <Layout component={FinalReportComponent}
                    path="/reports/tenants/dashboard/overall"
                    title="Overall Report"></Layout>
            </PrivateRoute>
        }
    />, ,
    <Route path="/console/tenants/reports/interview"
        element={
            <PrivateRoute>
                <Layout component={InterviewFormComponent}
                    path="/console/tenants/reports/interview"
                    title="Interview"></Layout>
            </PrivateRoute>
        }
    />,
    <Route path="/selected/students"
    element={
        <PrivateRoute>
            <Layout component={SelectedStudentsComponent}
                path="/selected/students"
                title="Selected Students"></Layout>
        </PrivateRoute>
    }
/>,
];